import './store'
import 'alpinejs';
import './lib/modal'
import alpineMixins from './alpine-mixins';
import sayHello from './lib/sayHello';
import './components/forms'
import './components/carousel'
import './components/promo-carousel'

window.alpineMixins = alpineMixins;
sayHello();
