import { Swiper, Navigation, EffectFade, Pagination } from 'swiper';

export default (() => {

  const carouselContainer = document.querySelector('.ui-promo-carousel');

  // eslint-disable-next-line
  if(!carouselContainer) return;

  Swiper.use([Navigation, EffectFade, Pagination]);

  const items = carouselContainer.querySelectorAll('.swiper-slide').length;
  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    loop: items > 1,
    watchOverflow: items === 1,
    autoplay: {
      delay: 5000,
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: carouselContainer.querySelector('.btn-carousel-next'),
      prevEl: carouselContainer.querySelector('.btn-carousel-prev'),
    },
    pagination: {
      el: carouselContainer.querySelector('.swiper-pagination'),
      type: 'bullets',
    },
  });
  carousel.init();

})();
