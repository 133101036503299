/* eslint-disable */
export default (() => {
  let modalOpenedInstance = null;
  window.mymodal = () => {
    return {
      isOpened: false,
      show(e) {
        const modal = document.querySelector(e.detail.name);
        if(modalOpenedInstance) return;
        if(modal) {
          document.querySelector('body').classList.add('modal-open')
          modalOpenedInstance = modal.__x.$data;
          modalOpenedInstance.isOpened = true
          Spruce.emit('modal.show', { event: e, modal });
        }
      },
      hide(e) {
        document.querySelector('body').classList.remove('modal-open')
        modalOpenedInstance.isOpened = false
        modalOpenedInstance = null;
        Spruce.emit('modal.hide', { event: e });
      }
    }
  };
})();
