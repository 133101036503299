/* eslint-disable */

import IMask from 'imask';
import Choices from 'choices.js';

export default (() => {

  const phonesFields          = document.querySelectorAll('input[data-mask^="phone"]');
  const customFieldsMask      = document.querySelectorAll('input[data-mask^="custom"]');
  const selects               = document.querySelectorAll('.ui-select');

  Array.from(phonesFields).forEach((field) => {
    const customMask = field.getAttribute('data-mask').split('|')[1]
    const mask = customMask || '+7(000)000-00-00'
    field.imask = IMask(field, {
      mask,
      lazy: true
    });
  });

  Array.from(customFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask').split('|')[1];
    if(!mask){
      console.error(`Не указана маска у поля ${field.name}`)
      return
    }
    field.imask = IMask(field, {
      mask
    });
  })

  Array.from(selects).forEach((item) => {
    const select = new Choices(item, {
      placeholder: true,
      searchEnabled: false,
      shouldSort: false,
      itemSelectText: '',
    });
  });

})();
